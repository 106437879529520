import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import {
  Button,
} from '@audi/audi-ui-react';
import './AccountsAndPermissions.scss';
import {
  Rootstate, Alert, PreviousAccount, AdditionalOwner, mandatoryFields, contactsMandatoryFields,
} from '../../AccountDetails/AccountDetailsHelpers';
import { accountsAndPermissionsFirst, accountsAndPermissionSecond } from '../../../helpers/inputFields';
import { axiosInstance } from '../../../axios';
import MultiInputFields from '../../shared/MultiInputFields';
import { editAccount, fetchActiveAccounts } from '../../../app/slices/accountListSlice';
import { accountState } from '../../../helpers/objects';
import SpeedDial from '../../shared/SpeedDial';
import ConfirmModal from '../../AccountDetails/ConfirmModal/ConfirmModal';
import '../AccountAddInfos.scss';

interface Props {
  handleAlert: ({ open, type }: Alert) => void;
}

const AccountsAndPermissions: FC<Props> = ({ handleAlert }) => {
  const dispatch = useDispatch();
  const title = 'Accounts And Permissions';
  const [openModalFirst, setOpenModalFirst] = useState(false);
  const [openModalSecond, setOpenModalSecond] = useState(false);
  const [actionType, setActionType] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [prevAccount, setPreviousAccount] = useState<PreviousAccount | undefined>();
  const [allFilled, setAllFilled] = useState(true);
  const [deleteAdditionalOwner, setDeleteAdditionalOwner] = useState<AdditionalOwner| undefined>();
  const userRole = useSelector((state: Rootstate) => state.user.userRole);
  const project = useSelector((state: Rootstate) => state.accounts.project);
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const account = useSelector((state: Rootstate) => state.accounts.account);
  const mandatoryChecks = contactsMandatoryFields.map((item) => account[item]);

  const fetchData = async () => {
    try {
      if (account.account_id) {
        dispatch(editAccount(accountState));
        const response = await axiosInstance().then((service) => service?.get(`/accounts/${account.account_id}`));
        const additional_owners = await axiosInstance().then((service) => service?.get(`/accounts/${account.account_id}/additional_owners`));
        const data = response?.data[0];
        Object.keys(accountState).map((key) => {
          if (data[key] === undefined) {
            data[key] = '';
          }
          return key;
        });
        const currentAccount = {
          cloud_provider: 'AWS',
          account_brand: data.brand,
          account_email: data.account_email,
          account_env: data.account_env,
          account_project: data.project,
          account_spec: data.account_spec,
          cost_center: data.cost_center,
          cost_psp_element: data.cost_psp_element,
          cost_assignment: data.cost_assignment || '8150000200',
          cost_center_wa: data.cost_center_wa,
          account_description: data.ext_info.comment,
          account_id: data.account_id,
          regions: data.allowed_regions,
          operations_contact: {
            full_name: data.contacts.operations.full_name,
            email: data.contacts.operations.email,
          },
          security_contact: {
            full_name: data.contacts.security.full_name,
            email: data.contacts.security.email,
          },
          requestor_contact: {
            full_name: data.ext_info.requestorFullName,
            email: data.ext_info.requestorEmailAddress,
          },
          account_name: data.account_name,
          project_name: data.project_name,
          budget_limit_alert: data.budget_limit_alert === '' ? '0,00' : data.budget_limit_alert,
          leanix_id: data.leanix_id,
          architect_assessment_date: data.architect_assessment_date,
          architect_assessment_performed: data.architect_assessment_performed,
          architect_fullname: data.architect_fullname,
          security_checkup_date: data.security_checkup_date,
          security_checkup_performed: data.security_checkup_performed,
          requestor_email: data.requestorEmailAddress,
          login_url: `https://${data.alias}.signin.aws.amazon.com/console`,
          welcome_mail_sent: data.welcome_mail_sent,
          pending_deletion: data.pending_deletion,
          additional_owners: additional_owners?.status !== 204 ? additional_owners?.data : [],
          new_additional_owner: {
            email: '',
            full_name: '',
          },
        };
        setPreviousAccount(
          currentAccount,
        );
        dispatch(editAccount(
          currentAccount,
        ));
        setPendingDeletion(data.pending_deletion);
      }
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  const checkValidateAllFields = () => {
    for (let i = 0; i < mandatoryChecks.length; i++) {
      const isEmpty = typeof mandatoryChecks[i] === 'undefined' ? true : Object.values(mandatoryChecks[i]).some((x) => x === '');
      if ((mandatoryChecks[i] === '') || isEmpty) { return setAllFilled(false); }
    }
    setAllFilled(true);
  };

  const toggleContent = () => {
    setVisibility(!visibility);
  };

  // disable or enable save button
  useEffect(() => {
    if (allFilled) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [account, pendingDeletion, allFilled]);

  useEffect(() => {
    checkValidateAllFields();
  }, [account]);

  const handleSubmit = async () => {
    if ((allFilled)) {
      try {
        const newObjBody: any = userRole === 'admin' ? {
          operations_contact:
          {
            full_name: account.operations_contact.full_name,
            email: account.operations_contact.email,
          },
          security_contact:
          {
            full_name: account.security_contact.full_name,
            email: account.security_contact.email,
          },
          requestor_contact:
          {
            full_name: account.requestor_contact.full_name,
            email: account.requestor_contact.email,
          },
          requestor_email: account.requestor_contact.email,
        } : {
          operations_contact:
          {
            full_name: account.operations_contact.full_name,
            email: account.operations_contact.email,
          },
          security_contact:
          {
            full_name: account.security_contact.full_name,
            email: account.security_contact.email,
          },
        };

        await axiosInstance().then((service) => service?.patch(`/accounts/${account.account_id}`, newObjBody))
          .then((res) => {
            dispatch(fetchActiveAccounts());
            handleAlert({ open: true, type: 'success', message: 'Successfully saved the changes' });
            setOpenModalFirst(false);
            setDisabledSave(true);
            fetchData();

            if (res?.status && res.status >= 400) {
              const errResponse = res?.data.message;
              handleAlert({ open: true, type: 'error', message: errResponse });
            }
          });
      } catch (error: any) {
        handleAlert({ open: true, type: 'error', message: error.message });
      }
    } else {
      handleAlert({ open: true, type: 'error', message: 'Please fill data into all required input fields' });
    }
  };

  const handleSubmitAo = async () => {
    try {
      const newObjBody: any = [{
        email: account.new_additional_owner.email,
        full_name: account.new_additional_owner.full_name,
      }];

      await axiosInstance().then((service) => service?.post(`/accounts/${account.account_id}/additional_owners`, newObjBody))
        .then((res) => {
          handleAlert({ open: true, type: 'success', message: 'Successfully saved the changes' });
          fetchData();

          if (res?.status && res.status >= 400) {
            const errResponse = res?.data.message;
            handleAlert({ open: true, type: 'error', message: errResponse });
          }
        });
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  const handleDelete = async () => {
    try {
      const newObjBody: any = [{
        email: deleteAdditionalOwner?.email,
        full_name: deleteAdditionalOwner?.full_name,
      }];

      await axiosInstance().then((service) => service?.delete(`/accounts/${account.account_id}/additional_owners`, { data: newObjBody }))
        .then((res) => {
          console.log(res.data.json);
          handleAlert({ open: true, type: 'success', message: 'Successfully saved the changes' });
          fetchData();

          if (res?.status && res.status >= 400) {
            const errResponse = res?.data.message;
            handleAlert({ open: true, type: 'error', message: errResponse });
          }
        });
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
    setOpenModalSecond(false);
  };

  const openConfirmModalFirst = (type: string) => {
    setOpenModalFirst(true);
    setActionType(type);
  };

  const openConfirmModalSecond = (type: string) => {
    setOpenModalSecond(true);
    setActionType(type);
  };

  const deleteAdditionalUser = async (ao_email: string, ao_full_name: string) => {
    setDeleteAdditionalOwner({
      email: ao_email,
      full_name: ao_full_name,
    });
    openConfirmModalSecond('delete');
  };

  const newUserValidate = () => {
    if (
      account.new_additional_owner.email.length === 0
      || account.new_additional_owner.full_name.length === 0
      || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(account.new_additional_owner.email)
    ) {
      return false;
    }
    return true;
  };

  const actions = [
    {
      icon: <SaveRoundedIcon />, name: 'Save', disabled: disabledSave, handleClick: () => openConfirmModalFirst('save'),
    },
  ];

  const userReadOnly = [
    'requestor_contact',
    'requestor_email',
  ];
  const adminReadOnly = [''];

  useEffect(() => {
    console.log(userRole);
    if (userRole === 'user') {
      accountsAndPermissionsFirst.forEach((element) => {
        console.log(element);
        if (userReadOnly.includes(element.name)) {
          console.log(element);
          element.children.forEach((children) => {
            console.log(children);
            children.readOnly = true;
          });
        }
      });
    } else if (userRole === 'admin') {
      accountsAndPermissionsFirst.forEach((element) => {
        if (adminReadOnly.includes(element.name)) {
          element.children.forEach((children) => {
            children.readOnly = true;
          });
        }
      });
    }
  }, [userRole]);

  return (
    <div>
      <button
        type="button"
        className={`add-info__sub-header collapsible ${visibility ? 'active' : ''}`}
        onClick={toggleContent}
      >
        {title}
      </button>
      {visibility && (
      <div className="account-details__form-group">
        <form action="">
          {accountsAndPermissionsFirst.map((item) => (
            <div key={item.name}>
              <MultiInputFields item={item} />
            </div>
          ))}
        </form>
        <div className="account-details__button-group">
          <SpeedDial actions={actions} />
        </div>
        <ConfirmModal
          type={actionType}
          name={account.account_name}
          show={openModalFirst}
          handleClose={() => setOpenModalFirst(false)}
          handleSubmit={handleSubmit}
        />
        <form action="">
          {accountsAndPermissionSecond.map((item) => (
            <div key={item.name}>
              <MultiInputFields item={item} aoDelete={deleteAdditionalUser} />
            </div>
          ))}
        </form>
        <div className="account-details__button-group">
          {
            newUserValidate()
              ? (
                <Button variant="primary" size="small" onClick={() => { dispatch(handleSubmitAo); }}>
                  + Add User
                </Button>
              )
              : (
                <Button variant="primary" size="small" disabled>
                  + Add User
                </Button>
              )
          }
        </div>
        <ConfirmModal
          type={actionType}
          name={deleteAdditionalOwner ? deleteAdditionalOwner?.email : ''}
          show={openModalSecond}
          handleClose={() => setOpenModalSecond(false)}
          handleSubmit={handleDelete}
        />
      </div>
      )}
    </div>
  );
};

export default AccountsAndPermissions;
